<template>
    <div id="messageDetail">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="back"
                v-if="showBack"
                class="backBtn"
                >返回</el-button
            >
            <div class="title">{{ title }}</div>
        </div>
        <table-list
            :ButtonWidth="120"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'customerName'">{{ $route.query.name }}</span>
                <span v-else-if="scope.colr.prop == 'stage'">
                    <span v-if="$route.query.isStage">第{{ scope.row[scope.colr.prop] }}/{{ $route.query.stageNumber }}期</span>
                    <span v-else>单笔扣款</span>
                </span>
                <span v-else-if="scope.colr.prop == 'amount'">{{ scope.row[scope.colr.prop] | numberToCurrency(2)}}</span>
            </template>
        </table-list>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import paymentOrderApi from "../../api/paymentOrderApi";
export default {
    name: "messageDetail",
    components: { TableList },

    data() {
        return {
            checkId: null,
            checkForm: {},
            showbigImg: false,
            bigImgSrc: "",
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            tableData: [],
            colrs: [
                { label: "订单号", prop: "orderId" },
                { label: "客户姓名", prop: "customerName" },
                { label: "期数", prop: "stage" },
                { label: "扣款金额(元)", prop: "amount" },
                { label: "扣款日期", prop: "repaymentTime" }
            ],
            newsNumber: 0,

            showBack: false,
            pageSize: 20,
            pageNumber: 0,
            total: 0,

            title: "",
            id: this.$route.query.id
        };
    },

    mounted() {
        this.showBack = true;
        this.getOrderInfo(this.$route.query.id);
        this.title = this.$route.query.id + " 订单扣款详情";
        document.title = "代扣系统-扣款详情";
        // this.$bus.$on("reload-data", this.getStages);
    },
    methods: {
        getOrderInfo(id) {
            paymentOrderApi.paymentRecordRead({ id: id });
            paymentOrderApi
                .paymentRecord({
                    id: id
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data;
                    }
                });
        },
        back() {
            this.$router.back();
        },
        check(row) {
            this.showBack = true;
            this.title = row.id + " 订单扣款详情";
            this.tableData = row.repaymentRecords || [];

            this.$set(this.$route.query, "id", row.id);

            this.colrs = [
                { label: "订单号", prop: "orderId" },
                { label: "客户姓名", prop: "customerName" },
                { label: "扣款金额", prop: "amount" },
                { label: "通知消息", prop: "message" },
                { label: "发送日期", prop: "createdAt" },
                { label: "状态", prop: "isRead" }
            ];
            paymentOrderApi.paymentRecordRead({ id: row.id });

            this.tableData.forEach(v => {
                v.message = v.stage + "/" + row.stageNumber + v.repaymentStatusDesc;
                v.customerName = row.customerName;
            });
        },

        currentChange(pageIndex) {
            this.pageIndex = pageIndex - 1;
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
        }
    }
};
</script>
<style>
</style>
<style lang="scss" scoped>
#messageDetail {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
    }
    .headBox {
        text-align: center;
        margin-bottom: 16px;
        position: relative;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .backBtn {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .right {
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }

    .box-card {
        margin-bottom: 20px;
        width: 100%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .readGreen {
        color: #67c23a;
    }
    .readRen {
        color: #f56c6c;
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }
}
</style>

